export function PlayDuoIcon({
  className,
  color = "currentColor",
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        {
          "--icon-color": color,
          "--icon-color-dark": `color-mix(in srgb, var(--icon-color) 35%, black)`,
        } as React.CSSProperties
      }
    >
      <path
        d="M22.25 12C22.25 17.6609 17.6609 22.25 12 22.25C6.33908 22.25 1.75 17.6609 1.75 12C1.75 6.33908 6.33908 1.75 12 1.75C17.6609 1.75 22.25 6.33908 22.25 12Z"
        fill="var(--icon-color-dark)"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3444 11.6357C16.6301 11.7945 16.6301 12.2054 16.3444 12.3642L10.119 15.8227C9.8413 15.977 9.5 15.7762 9.5 15.4585V8.54141C9.5 8.22371 9.8413 8.02289 10.119 8.17718L16.3444 11.6357Z"
        fill="var(--icon-color)"
      />
    </svg>
  );
}
