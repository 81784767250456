import { Link } from "@remix-run/react";
import classnames from "classnames";

type NavLinkProps = Parameters<typeof Link>[0];

export function NavLink({
  to,
  children,
  target,
  className,
  onClick,
  ...props
}: NavLinkProps) {
  return (
    <Link
      to={to}
      onClick={onClick}
      target={target}
      className={classnames(
        "inline-block whitespace-nowrap text-sm text-bright transition hover:text-secondary",
        className
      )}
      {...props}
    >
      {children}
    </Link>
  );
}
