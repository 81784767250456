import { Footer } from "./primitives/Footer";
import { Nav } from "./primitives/Nav";

export function AppLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Nav />
      <main>{children}</main>
      <Footer />
    </>
  );
}
