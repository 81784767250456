import { StarIcon } from "@heroicons/react/24/outline";

import { useLocation, useMatches } from "@remix-run/react";
import { AnimatePresence, MotionConfig, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { formatGitHubStar } from "../formatGithubStar";
import { AnimatingArrow } from "./AnimatingArrow";
import { LinkButton } from "./Buttons";

import { cn } from "~/utils/cn";
import { MenuItemDescriptive } from "../MenuItemDescriptive";
import { DiscordIconWhite } from "../icons/DiscordIcon";
import { CalenderDuoIcon } from "../icons/custom-icons/CalenderDuoIcon";
import { ConcurrencyParallelDuoIcon } from "../icons/custom-icons/ConcurrencyParallelDuoIcon";
import { PlayDuoIcon } from "../icons/custom-icons/PlayDuoIcon";
import { RoadmapDuoIcon } from "../icons/custom-icons/RoadmapDuoIcon";
import { WrenchDuoIcon } from "../icons/custom-icons/WrenchDuoIcon";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./Accordion";
import { EyeOpenDuoIcon } from "../icons/custom-icons/EyeOpenDuoIcon";

function useStarCount(): number {
  const matches = useMatches();
  return matches.find((m) => m.id === "root")?.data.stargazers ?? 0;
}

export function MobileNavMenu({ className }: { className?: string }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  const toggleMenu = () => setOpen((prevOpen) => !prevOpen);
  const stargazers = useStarCount();
  const menuVariants = {
    initial: { scaleY: 0 },
    animate: { scaleY: 1 },
    exit: { scaleY: 0 },
  };
  const linkVariants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0, transition: { delay: 0.3 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.1 } },
  };
  const burgerMenuVariants = {
    top: {
      open: {
        rotate: ["0deg", "0deg", "45deg"],
        top: ["33.333%", "50%", "50%"],
      },
      closed: {
        rotate: ["45deg", "0deg", "0deg"],
        top: ["50%", "50%", "33.333%"],
      },
    },
    bottom: {
      open: {
        rotate: ["0deg", "0deg", "-45deg"],
        top: ["66.666%", "50%", "50%"],
      },
      closed: {
        rotate: ["-45deg", "0deg", "0deg"],
        top: ["50%", "50%", "66.666%"],
      },
    },
  };

  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className={cn("flex items-center", className)}>
      <MotionConfig transition={{ duration: 0.2, ease: "easeInOut" }}>
        <motion.button
          initial={false}
          animate={open ? "open" : "closed"}
          aria-label="Toggle navigation"
          onClick={toggleMenu}
          className="relative z-50 size-6 focus-visible:ring-1 focus-visible:ring-lavender-500 focus-visible:ring-offset-2"
        >
          <motion.span
            variants={burgerMenuVariants.top}
            className="absolute h-0.5 w-6 rounded-full bg-bright"
            style={{ x: "-50%", y: "-50%", left: "50%", top: "33.333%" }}
          />
          <motion.span
            variants={burgerMenuVariants.bottom}
            className="absolute h-0.5 w-6 rounded-full bg-bright"
            style={{ x: "-50%", y: "-50%", left: "50%", top: "66.666%" }}
          />
        </motion.button>
      </MotionConfig>
      <AnimatePresence>
        {open && (
          <motion.div
            variants={menuVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="fixed left-0 top-[60px] flex h-dvh w-full origin-top bg-background text-dimmed"
          >
            <motion.div
              variants={linkVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              className="flex h-full max-h-full w-full flex-col px-3"
            >
              <div className="flex h-full max-h-full flex-col overflow-y-auto border-x border-grid-dimmed px-3 pb-40">
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="item-1">
                    <AccordionTrigger>Product</AccordionTrigger>
                    <AccordionContent className="space-y-3">
                      <MenuItemDescriptive
                        to="/#how-it-works"
                        icon={
                          <PlayDuoIcon className="size-5 text-lavender-500" />
                        }
                        title="How it works"
                        description="Trigger.dev in 40 seconds."
                      />

                      <MenuItemDescriptive
                        to="/product/concurrency-and-queues"
                        icon={
                          <ConcurrencyParallelDuoIcon className="size-5 text-barbie-500" />
                        }
                        title="Concurrency & queues"
                        description="Control how many tasks run at once."
                      />
                      <MenuItemDescriptive
                        to="/product/scheduled-tasks"
                        icon={
                          <CalenderDuoIcon className="size-5 text-primary" />
                        }
                        title="Scheduled tasks"
                        description="Create recurring cron schedules."
                      />
                      <MenuItemDescriptive
                        to="/product/observability-and-monitoring"
                        icon={
                          <EyeOpenDuoIcon className="size-5 text-aqua-500" />
                        }
                        title="Observability & monitoring"
                        description="Real-time monitoring and tracing of tasks."
                      />
                      <MenuItemDescriptive
                        to="/product"
                        icon={
                          <WrenchDuoIcon className="size-5 text-charcoal-400" />
                        }
                        title="All features"
                        description="Explore the full featureset."
                      />
                      <MenuItemDescriptive
                        to="https://feedback.trigger.dev/roadmap"
                        icon={
                          <RoadmapDuoIcon className="size-5 text-sun-500" />
                        }
                        title={
                          <span className="flex items-center gap-0.5">
                            Roadmap{" "}
                            <AnimatingArrow
                              variant="small"
                              direction="topRight"
                            />
                          </span>
                        }
                        description="See what we're building next."
                        className="pb-3"
                      />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
                <LinkButton
                  variant="mobile-nav-item"
                  to="/changelog"
                  textAlignLeft
                  fullWidth
                  className="border-t-0"
                >
                  Changelog
                </LinkButton>
                <LinkButton
                  variant="mobile-nav-item"
                  to="/blog"
                  textAlignLeft
                  fullWidth
                >
                  Blog
                </LinkButton>
                <LinkButton
                  variant="mobile-nav-item"
                  to="https://trigger.dev/docs"
                  textAlignLeft
                  target="_blank"
                  fullWidth
                >
                  Docs
                </LinkButton>
                <LinkButton
                  variant="mobile-nav-item"
                  to="/pricing"
                  textAlignLeft
                  fullWidth
                >
                  Pricing
                </LinkButton>
                <LinkButton
                  variant="mobile-nav-item"
                  to="https://trigger.dev/discord"
                  target="_blank"
                  id="GitHub link"
                  fullWidth
                >
                  <span className="flex w-full items-center justify-between">
                    <span className="flex items-center gap-x-0.5 text-base font-normal text-bright">
                      Join our community
                    </span>
                    <DiscordIconWhite className="size-5 opacity-50" />
                  </span>
                </LinkButton>
                <LinkButton
                  variant="mobile-nav-item"
                  to="https://github.com/triggerdotdev/trigger.dev"
                  target="_blank"
                  id="GitHub link"
                  fullWidth
                >
                  <span className="flex w-full items-center justify-between">
                    <span className="flex items-center gap-x-0.5 text-base font-normal text-bright">
                      Star us on GitHub
                    </span>
                    <div className="flex items-center gap-1 text-base">
                      <StarIcon className="mb-0.5 ml-1 size-5" />
                      {formatGitHubStar(stargazers)}
                    </div>
                  </span>
                </LinkButton>
              </div>
              <motion.div
                className="fixed bottom-0 left-0 flex w-full gap-x-2 border-t border-grid-dimmed bg-background p-3 pb-0"
                initial={{ bottom: -70 }}
                animate={{ bottom: 0 }}
                transition={{ delay: 0.4 }}
              >
                <LinkButton
                  variant="tertiary/large"
                  target="_self"
                  to="https://cloud.trigger.dev"
                  fullWidth
                  className="mb-4"
                >
                  Login
                </LinkButton>
                <LinkButton
                  variant="primary/large"
                  target="_self"
                  to="https://cloud.trigger.dev"
                  fullWidth
                  className="mb-2"
                  TrailingIcon={() => (
                    <AnimatingArrow variant="large" theme="dark" />
                  )}
                >
                  Get started now
                </LinkButton>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
