export function WrenchDuoIcon({
  className,
  color = "currentColor",
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        {
          "--icon-color": color,
          "--icon-color-dark": `color-mix(in srgb, var(--icon-color) 35%, black)`,
        } as React.CSSProperties
      }
    >
      <path
        d="M3.00001 6.75C1.96447 5.71446 1.96447 4.03553 3 3C4.03554 1.96446 5.71447 1.96446 6.75 3L12.5 9L14 12L18.091 16.5L19.5 16.5L21.75 20.25L20.25 21.75L16.5 19.5L16.5 18.091L12.5 13.5L9.5 12.5L3.00001 6.75Z"
        fill="var(--icon-color-dark)"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 17.25C2.25 14.6473 4.55027 12.5477 7.13411 12.7662C9.96591 12.5396 10.8234 10.9645 12.5806 8.83064L17.2357 3.1781C17.7196 2.59045 18.441 2.25 19.2023 2.25C20.6094 2.25 21.75 3.39064 21.75 4.79769C21.75 5.55897 21.4096 6.28037 20.8219 6.76432L13.9854 12.3944L12.2404 13.8314L12.1383 13.9155C11.3052 14.6016 11.1429 15.7904 11.2338 16.8659C11.2445 16.9925 11.25 17.1206 11.25 17.25C11.25 19.7353 9.23528 21.75 6.75 21.75C6.08965 21.75 5.46253 21.6078 4.8976 21.3523L8.17383 18.076C7.91771 16.9604 7.03949 16.0821 5.92383 15.826L2.64765 19.1022C2.3922 18.5373 2.25 17.9103 2.25 17.25Z"
        fill="var(--icon-color-dark)"
      />
      <path
        d="M7.13411 12.7662C9.70619 12.9836 11.1099 10.6166 12.5806 8.83064M7.13411 12.7662C9.96591 12.5396 10.8234 10.9645 12.5806 8.83064M7.13411 12.7662C4.55027 12.5477 2.25 14.6473 2.25 17.25C2.25 17.9103 2.3922 18.5373 2.64765 19.1022L5.92383 15.826C7.03949 16.0821 7.91771 16.9604 8.17383 18.076L4.8976 21.3523C5.46253 21.6078 6.08965 21.75 6.75 21.75C9.23528 21.75 11.25 19.7353 11.25 17.25C11.25 17.1206 11.2445 16.9925 11.2338 16.8659C11.1429 15.7904 11.3052 14.6016 12.1383 13.9155L12.2404 13.8314L13.9854 12.3944L20.8219 6.76432C21.4096 6.28037 21.75 5.55897 21.75 4.79769C21.75 3.39064 20.6094 2.25 19.2023 2.25C18.441 2.25 17.7196 2.59045 17.2357 3.1781L12.5806 8.83064"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
