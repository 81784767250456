import {
  BoltIcon,
  BookOpenIcon,
  CodeBracketSquareIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  GlobeEuropeAfricaIcon,
  HeartIcon,
  LockClosedIcon,
  MapIcon,
  MegaphoneIcon,
  NewspaperIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  StarIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { Link } from "@remix-run/react";
import { GitHubLightIcon, XLightIcon } from "@trigger.dev/companyicons";
import cx from "classnames";
import React from "react";
import { SocialMediaLink } from "../SocialMediaLink";
import StatusWidget from "../UptimeStatus";
import { DiscordIconWhite } from "../icons/DiscordIcon";
import { GithubIcon } from "../icons/GitHubIcon";
import { LinkedInIcon } from "../icons/LinkedInIcon";
import { GridContent, GridRow } from "./GridRow";
import { Section } from "./Section";
import { TriggerdotdevLogo } from "./TriggerdotdevLogo";
import { Header4 } from "./text/Header";
import { Paragraph } from "./text/Paragraph";

type FooterItemProps = {
  title?: string;
  icon: React.ComponentType<any>;
  ariaLabel: string;
  external?: boolean;
  footerElementStyles?: string;
  to: string;
};

export function Footer() {
  return (
    <footer className="w-full">
      <Section id="footer" className="mx-auto">
        <GridRow
          autoResponsiveGrid={false}
          autoGridLines={false}
          className="divide-y divide-grid-dimmed border-l-0 bg-visible-grid-2 sm:bg-visible-grid-4 lg:divide-y-0 lg:bg-visible-grid-6"
        >
          <GridContent className="col-span-12 flex items-center justify-between border-l border-grid-dimmed bg-background lg:col-span-4 lg:flex lg:flex-col lg:items-start lg:border-l-0 lg:border-none lg:bg-transparent">
            <a href="#top">
              <TriggerdotdevLogo className="w-28 sm:w-32 lg:w-40" />
            </a>
            <div className="flex items-center gap-x-3">
              <SocialMediaLink
                icon={DiscordIconWhite}
                to={"/discord"}
                ariaLabel={"Discord link"}
                className="brightness-50"
              />

              <SocialMediaLink
                icon={GitHubLightIcon}
                to="https://github.com/triggerdotdev/trigger.dev"
                ariaLabel={"GitHub link"}
                className="brightness-50"
              />
              <SocialMediaLink
                icon={XLightIcon}
                to="https://twitter.com/triggerdotdev"
                ariaLabel={"X (Twitter) link"}
                className="brightness-50"
              />
              <SocialMediaLink
                icon={LinkedInIcon}
                to="https://www.linkedin.com/company/triggerdotdev"
                ariaLabel={"LinkedIn link"}
                className="text-white brightness-50"
              />
            </div>
          </GridContent>
          <GridContent className="col-span-6 place-content-start gap-y-4 sm:col-span-3 lg:col-span-2 lg:gap-y-4">
            <Header4
              variant="small"
              className="font-medium text-bright sm:text-base"
            >
              Docs
            </Header4>
            {docsItems.map((item) => (
              <FooterItem
                key={item.title}
                title={item.title}
                icon={item.icon}
                to={item.to}
                ariaLabel={item.ariaLabel}
              />
            ))}
          </GridContent>
          <GridContent className="col-span-6 place-content-start gap-y-4 sm:col-span-3 lg:col-span-2">
            <Header4
              variant="small"
              className="font-medium text-bright sm:text-base"
            >
              Developers
            </Header4>
            {developersItems.map((item) => (
              <FooterItem
                key={item.title}
                title={item.title}
                icon={item.icon}
                to={item.to}
                ariaLabel={item.ariaLabel}
              />
            ))}
          </GridContent>
          <GridContent className="col-span-6 place-content-start gap-y-4 sm:col-span-3 lg:col-span-2">
            <Header4
              variant="small"
              className="font-medium text-bright sm:text-base"
            >
              Product
            </Header4>
            {productItems.map((item) => (
              <FooterItem
                key={item.title}
                title={item.title}
                icon={item.icon}
                to={item.to}
                ariaLabel={item.ariaLabel}
              />
            ))}
          </GridContent>
          <GridContent className="col-span-6 place-content-start gap-y-4 sm:col-span-3 lg:col-span-2">
            <Header4
              variant="small"
              className="font-medium text-bright sm:text-base"
            >
              Company
            </Header4>
            {companyItems.map((item) => (
              <FooterItem
                key={item.title}
                title={item.title}
                icon={item.icon}
                to={item.to}
                ariaLabel={item.ariaLabel}
              />
            ))}
          </GridContent>
        </GridRow>
        <GridRow>
          <GridContent className="col-span-12 flex flex-col gap-y-3 py-5 xs:flex-row xs:justify-between xs:py-4 xs:pr-4">
            <StatusWidget />
            <Paragraph
              variant={"small"}
              className="whitespace-nowrap pl-2 text-dimmed xs:pl-0"
            >
              &copy; {new Date().getFullYear()} Trigger.dev
            </Paragraph>
          </GridContent>
        </GridRow>
      </Section>
    </footer>
  );
}

function FooterItem({ title, icon: Icon, ariaLabel, to }: FooterItemProps) {
  const content = (
    <div className="group flex items-center gap-1.5">
      {Icon && (
        <Icon
          className={cx(
            "size-4 text-dimmed transition group-hover:text-bright"
          )}
        />
      )}
      <Paragraph variant="small" className="transition group-hover:text-bright">
        {title}
      </Paragraph>
    </div>
  );

  if (to.startsWith("http")) {
    return (
      <a
        href={to}
        aria-label={ariaLabel}
        target="_blank"
        rel="noopener noreferrer"
        title={title}
      >
        {content}
      </a>
    );
  } else {
    return (
      <Link to={to} aria-label={ariaLabel} title={title}>
        {content}
      </Link>
    );
  }
}

const docsItems = [
  {
    title: "Introduction",
    icon: BookOpenIcon,
    to: "https://trigger.dev/docs/introduction",
    ariaLabel: "Introduction",
  },
  {
    title: "Quick start guide",
    icon: BookOpenIcon,
    to: "https://trigger.dev/docs/quick-start",
    ariaLabel: "Quick start guide",
  },
  {
    title: "The /trigger folder",
    icon: BookOpenIcon,
    to: "https://trigger.dev/docs/trigger-folder",
    ariaLabel: "The /trigger folder",
  },
  {
    title: "Creating a task",
    icon: BookOpenIcon,
    to: "https://trigger.dev/docs/writing-tasks-introduction",
    ariaLabel: "Creating a task",
  },
  {
    title: "Triggering a task",
    icon: BookOpenIcon,
    to: "https://trigger.dev/docs/triggering",
    ariaLabel: "Triggeing a task",
  },
  {
    title: "Self hosting",
    icon: BookOpenIcon,
    to: "https://trigger.dev/docs/open-source-self-hosting",
    ariaLabel: "Self hosting",
  },
];

const developersItems = [
  {
    title: "Docs",
    icon: BookOpenIcon,
    to: "https://trigger.dev/docs",
    ariaLabel: "Docs",
  },
  {
    title: "Changelog",
    icon: MegaphoneIcon,
    to: "/changelog",
    ariaLabel: "Changelog",
  },
  {
    title: "Contributing",
    icon: CodeBracketSquareIcon,
    to: "https://github.com/triggerdotdev/trigger.dev/blob/main/CONTRIBUTING.md",

    ariaLabel: "Contributing",
  },
  {
    title: "Open source",
    icon: GlobeEuropeAfricaIcon,
    to: "https://github.com/triggerdotdev/trigger.dev?tab=Apache-2.0-1-ov-file#readme",
    ariaLabel: "Open source",
  },
  {
    title: "GitHub",
    icon: GithubIcon,
    to: "https://github.com/triggerdotdev/trigger.dev",
    ariaLabel: "GitHub",
  },
];

const productItems = [
  {
    title: "Pricing",
    icon: CurrencyDollarIcon,
    to: "/pricing",
    ariaLabel: "Pricing",
  },
  {
    title: "How it works",
    icon: RocketLaunchIcon,
    to: "/#how-it-works",
    ariaLabel: "How it works",
  },
  {
    title: "Features",
    icon: WrenchScrewdriverIcon,
    to: "/product",
    ariaLabel: "Product",
  },
  {
    title: "Roadmap",
    icon: MapIcon,
    to: "https://feedback.trigger.dev/roadmap",
    ariaLabel: "Roadmap",
  },
  {
    title: "FAQs",
    icon: QuestionMarkCircleIcon,
    to: "/pricing#faqs",
    ariaLabel: "FAQs",
  },
  {
    title: "Uptime status",
    icon: BoltIcon,
    to: "https://status.trigger.dev/",
    ariaLabel: "Uptime status",
  },
];

const companyItems = [
  {
    title: "Blog",
    icon: NewspaperIcon,
    to: "/blog",
    ariaLabel: "Blog",
  },
  {
    title: "Contact",
    icon: EnvelopeIcon,
    to: "/contact",
    ariaLabel: "Email",
  },
  {
    title: "Careers",
    icon: StarIcon,
    to: "https://trigger.dev/jobs",
    ariaLabel: "Blog",
  },
  {
    title: "Privacy",
    icon: LockClosedIcon,
    to: "/legal/privacy",
    ariaLabel: "Privacy policy",
  },
  {
    title: "Terms",
    icon: DocumentTextIcon,
    to: "/legal",
    ariaLabel: "Terms and conditions",
  },
  {
    title: "OSS friends",
    icon: HeartIcon,
    to: "/oss-friends",
    ariaLabel: "OSS friends",
  },
];
