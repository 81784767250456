import { cn } from "~/utils/cn";
import { NavLink } from "./primitives/NavLink";
import { Header4 } from "./primitives/text/Header";
import { Paragraph } from "./primitives/text/Paragraph";
import { AnimatingArrow } from "./primitives/AnimatingArrow";

const menuItemVariants = {
  "mobile-menu": {
    iconStyle: "rounded bg-charcoal-800 p-2",
    titleStyle: "",
    descriptionStyle: "transition duration-300",
    isDesktop: false,
    bgStyle: "",
  },
  "desktop-menu": {
    iconStyle: "p-3",
    titleStyle: "transition duration-300 sm:group-hover:text-white",
    descriptionStyle:
      "transition duration-300 sm:group-hover:text-charcoal-300",
    isDesktop: true,
    bgStyle:
      "group flex items-center gap-x-1.5 rounded py-2 pr-3 transition hover:bg-charcoal-750",
  },
};

export type Variants = keyof typeof menuItemVariants;

type MenuItemDescriptiveProps = {
  to: string;
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  variant?: Variants;
  className?: string;
  externalLink?: boolean;
};

export function MenuItemDescriptive({
  to,
  icon,
  title,
  description,
  variant = "mobile-menu",
  className,
  externalLink,
}: MenuItemDescriptiveProps) {
  const variantStyles = menuItemVariants[variant];
  return (
    <NavLink
      target={externalLink ? "_blank" : undefined}
      to={to}
      className={cn(
        "group flex items-center gap-3",
        variantStyles.bgStyle,
        className
      )}
    >
      <div className={variantStyles.iconStyle}>{icon}</div>
      <div className="flex flex-col gap-0">
        <Header4 variant="extra-small" className={variantStyles.titleStyle}>
          {title}
        </Header4>
        <Paragraph variant="small" className={variantStyles.descriptionStyle}>
          {description}
        </Paragraph>
      </div>
      {externalLink && variantStyles.isDesktop ? (
        <AnimatingArrow
          theme="dimmed"
          variant="small"
          direction="topRight"
          className="ml-auto opacity-0 transition duration-300 group-hover:opacity-100"
        />
      ) : null}
    </NavLink>
  );
}
