export default function StatusWidget() {
  return (
    <div
      style={{
        colorScheme: "light",
      }}
    >
      <iframe
        title="Uptime status"
        src="https://status.trigger.dev/badge?theme=dark"
        width="250"
        height="30"
      ></iframe>
    </div>
  );
}
