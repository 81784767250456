export function SupabaseSolidColourLogo({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="Supabase"
      className={className}
    >
      <path
        d="M139.546 236.013C133.414 243.734 120.983 239.503 120.835 229.645L118.675 85.4478H215.633C233.194 85.4478 242.989 105.732 232.069 119.485L139.546 236.013Z"
        fill="#878C99"
      />

      <path
        d="M100.114 3.98779C106.245 -3.73415 118.677 0.49692 118.824 10.3559L119.771 154.552H24.0265C6.46435 154.552 -3.3304 134.269 7.59028 120.515L100.114 3.98779Z"
        fill=" #B5B8C0"
      />
    </svg>
  );
}
