import { cn } from "~/utils/cn";

export function SocialMediaLink({
  to,
  ariaLabel,
  icon: Icon,
  className,
}: {
  to: string;
  ariaLabel: string;
  icon: React.ComponentType<any>;
  className?: string;
}) {
  return (
    <a
      href={to}
      aria-label={ariaLabel}
      target="_blank"
      rel="noopener noreferrer"
      className="group rounded border border-grid-bright p-2"
    >
      {Icon && (
        <Icon
          className={cn(
            "size-4 brightness-50 transition group-hover:brightness-100 group-hover:grayscale-0",
            className
          )}
        />
      )}
    </a>
  );
}
